body {
  margin: 0;
  padding: 0;
  font-family: "Proxima Nova Soft", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  color: #530000;
  font-size: 6vh !important;
  font-family: "Palatino header" !important;
  text-shadow: 0px 0px 10px #fff;
}

h2 {
  color: #fff;
  font-size: 4vh !important;
  text-shadow: 0px 0px 10px rgba(83, 0, 0, 1);
  margin-bottom: 1.5rem !important;
  font-family: "Palatino header" !important;
}

p {
  color: rgba(83, 0, 0, 1);
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 0rem !important;
}

a {
  text-decoration: none;
  color: rgba(83, 0, 0, 1) !important;
}

button {
  width: 150px;
  height: 45px;
  border: 0px solid grey !important;
  background-color: rgba(83, 0, 0, 0.7) !important;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 1s;
}

button:hover {
  background-color: rgba(83, 0, 0, 1) !important;
  transition: background-color 1s;
}

button:focus {
  background-color: rgba(83, 0, 0, 1) !important;
  box-shadow: 0 0 0 0.1rem rgba(255, 255, 255, 0.3) !important;
  transition: box-shadow 0.15s ease-in-out;
  transition: background-color 1s;
}

.headline-container {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  z-index: 2;
}

.headline {
  position: absolute;
  bottom: 20vh;
  transform: translate(-50%, -50%);
  text-align: center;
  top: 50%;
  left: 50%;
}

.nav-link {
  color: #fff !important;
  position: relative;
  border-bottom: 3px solid transparent;
  transition: border-bottom 1s;
  margin: 0 !important;
  padding: 0 !important;
  margin-left: 15px !important;
  padding-bottom: 3px !important;
  font-size: 24px;
  font-weight: normal;
  font-variant: small-caps;
  text-shadow: 0px 0px 10px rgba(83, 0, 0, 1);
}

.nav-link:hover {
  color: rgba(83, 0, 0, 1) !important;
  border-bottom: 3px solid #fff;
  transition: border-bottom 1s;
}
.nav-link.active {
  color: rgba(83, 0, 0, 1) !important;
  border-bottom: 2px solid #fff;
  transition: border-bottom 1s;
}

.sectionWrapper {
  width: 100%;
  margin: 40px 0px;
  align-content: center;
  text-align: center;
  /* padding: 20px; */
  line-height: 30px;
}

.rowWithPadding {
  text-align: center;
  padding: 30px;
}

.figure-caption {
  background-color: rgba(83, 0, 0, 1) !important;
  color: #fff !important;
  border-radius: 5px;
  top: -10px;
}

.page-link {
  background-color: rgba(83, 0, 0, 0.5) !important;
  border: 1px solid rgba(83, 0, 0, 0.7) !important;
  color: #fff !important;
  cursor: pointer !important;
}

.page-item.active .page-link {
  background-color: rgba(83, 0, 0, 1) !important;
}

.collapsed,
.navbar-toggler {
  width: auto !important;
  background-color: transparent !important;
}

.modal-body {
  display: flex;
  justify-content: center;
  background-color: transparent !important;
}

.modal-content {
  background-color: transparent !important;
  border: 0px !important;
}

.modal-body img {
  max-width: 80%;
  max-height: 80%;
}

.video-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

.video-container video {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
}

.color-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2;
}

.content-row-padding {
  padding-top: 50px;
}

.decoration-container {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.decoration-container > img {
  position: absolute;
}

.decoration-container > img:first-child {
  height: 90vh;
  top: -30px;
  right: -50px;
  opacity: 0.5;
}

.decoration-container > img:last-child {
  height: 80vh;
  left: 0px;
  opacity: 0.8;
}

@media only screen and (max-width: 900px) {
  .decoration-container > img:first-child {
    height: 60vh;
    top: -30px;
    right: -50px;
  }
}
